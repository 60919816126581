<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">考试查询</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="operationControl flexdc operationControlExamination"
          style="align-items: flex-start"
        >
          <div class="searchbox" style="display: flex; align-items: center">
            <div title="学员姓名" class="searchboxItem ci-full">
              <span class="itemLabel">学员姓名:</span>
              <el-input
                v-model="seaUserName"
                type="text"
                size="small"
                placeholder="请输入学员姓名"
                clearable
              />
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input
                v-model="seaUserIdcard"
                type="text"
                size="small"
                placeholder="请输入身份证号"
                clearable
              />
            </div>
            <div title="学员电话" class="searchboxItem ci-full">
              <span class="itemLabel">学员电话:</span>
              <el-input
                v-model="seaUserMobile"
                type="text"
                size="small"
                placeholder="请输入学员电话"
                clearable
              />
            </div>
          </div>
          <!-- 班级搜索拆开内容 -->
          <div
            class="searchbox"
            style="display: flex; align-items: center; margin-top: 10px"
          >
            <div title="班级编号" class="searchboxItem ci-full">
              <span class="itemLabel">班级编号:</span>
              <el-input
                v-model="seaProjectCode"
                type="text"
                size="small"
                placeholder="请输入班级编号"
                clearable
              />
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="seaProjectName"
                type="text"
                size="small"
                placeholder="请输入班级名称"
                clearable
              />
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-select
                size="small"
                v-model="compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
                style="width: 100%"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div
            class="searchbox"
            style="display: flex; align-items: center; margin-top: 10px"
          >
            <div title="试卷名称" class="searchboxItem ci-full">
              <span class="itemLabel">试卷名称:</span>
              <el-input
                v-model="paperName"
                type="text"
                size="small"
                placeholder="请输入试卷名称"
                clearable
              />
            </div>
            <div title="课件名称" class="searchboxItem ci-full">
              <span class="itemLabel">课件名称:</span>
              <el-input
                v-model="catalogName"
                type="text"
                size="small"
                placeholder="请输入课件名称"
                clearable
              />
            </div>
            <div title="考试类型" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 80px">考试类型:</span>
              <el-select
                v-model="paperUserType"
                placeholder="请选择"
                clearable
                size="small"
              >
                <el-option
                  v-for="item in paperusetype"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="是否及格" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 80px">是否及格:</span>
              <el-select
                v-model="paperIsPass"
                placeholder="请选择"
                clearable
                size="small"
              >
                <el-option
                  v-for="item in PassNot"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
            <el-button type="primary" class="bgc-bv" round @click="getExport()"
              >导出</el-button
            >
          </div>
          <!-- <div class="searchbox" style="margin-top: 10px"> -->

          <!-- </div> -->
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="60"
                fixed
                :index="indexMethod"
              />
              <el-table-column
                label="姓名"
                align="left"
                show-overflow-tooltip
                prop="userName"
                width="80"
                fixed
              />
              <el-table-column
                label="身份证号"
                align="left"
                show-overflow-tooltip
                prop="idcard"
                width="160"
              />
              <el-table-column
                label="手机号"
                align="left"
                show-overflow-tooltip
                prop="mobile"
                width="120"
              />
              <el-table-column
                label="机构名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
                width="200"
              />
              <el-table-column
                label="班级名称"
                width="200"
                align="left"
                show-overflow-tooltip
                prop="projectName"
              />
              <el-table-column
                label="班级编码"
                width="200"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
              />
              <el-table-column
                label="课程名称"
                width="250"
                align="left"
                show-overflow-tooltip
                prop="courseName"
              />
              <el-table-column
                label="课件名称"
                align="left"
                show-overflow-tooltip
                prop="catalogName"
                width="300"
              >
                <template slot-scope="scope">{{
                  scope.row.catalogName || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="试卷名称"
                align="left"
                show-overflow-tooltip
                prop="paperName"
                width="300"
              />
              <el-table-column
                label="考试类型"
                align="center"
                show-overflow-tooltip
                prop="paperUserType"
                width="80"
              >
                <template slot-scope="scope">{{
                  $setDictionary("PAPERUSERTYPE", scope.row.paperUserType)
                }}</template>
              </el-table-column>
              <el-table-column
                label="考试时间"
                class="cell2"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                width="280"
              >
              <template slot-scope="{row}">
                {{ row.joinTime }}~{{ row.submitTime }}
              </template>
              </el-table-column>
              <el-table-column
                label="考试用时"
                align="right"
                show-overflow-tooltip
                prop="examUseTime"
                width="80"
              />
              <el-table-column
                label="成绩"
                align="right"
                show-overflow-tooltip
                prop="paperScore"
                width="80"
              />
              <el-table-column
                label="是否及格"
                align="center"
                width="80"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.paperIsComplete ? "是" : "否"
                }}</template>
              </el-table-column>
              <el-table-column
                label="正确率"
                align="right"
                width="80"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.accuracyStr || "0%"
                }}</template>
              </el-table-column>
              <el-table-column label="操作" align="center" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handledetail(scope.row)"
                    >答题详情</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";

export default {
  name: "operate/Examination",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  doNotInit:true,
  data() {
    return {

      seaUserName: "", //学员姓名
      seaUserIdcard: "", //学员身份证号码
      seaUserMobile: "", //学员电话
      seaProjectCode: "", //班级编号
      seaProjectName: "", //班级名称
      dialogVisible: false,
      catalogName: "",
      paperName: "",
      compId: "",
      CompanyList: [],
      paperUserType: "",
      paperusetype: [],
      PassNot: [
        {
          value: "",
          label: "全部",
        },
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      paperIsPass: "",
    };
  },
  created() {
    this.getTableHeight();
    this.getpaperusertypeList();
  },
   computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        console.log(oldVal.query);
        if (val.query.liuliuliu) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  methods: {
    getpaperusertypeList() {
      const studylist = this.$setDictionary("PAPERUSERTYPEQUERY", "list");
      const list = [];
      for (const key in studylist) {
        list.push({
          value: key,
          label: studylist[key],
        });
      }
      this.paperusetype = list;
    },
    getData(pageNum = 1) {
      if(!this.seaProjectName&&!this.compId&&!this.seaProjectCode){
        this.$message.warning('至少选择一个班或机构')
        return
      }
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      let par = {
        catalogName: this.catalogName || "",
        paperName: this.paperName || "",
        paperIsComplete: this.paperIsPass,
        paperUserType: this.paperUserType || "",
        compId: this.compId,
        userName: this.seaUserName || "",
        idcard: this.seaUserIdcard || "",
        mobile: this.seaUserMobile || "",
        projectCode: this.seaProjectCode || "",
        projectName: this.seaProjectName || "",
      };
      params = {
        ...params,
        ...par,
      };
      this.doFetch({
        url: "/biz/paperUser/govListPage",
        params,
        pageNum,
      });
    },
    // 资源提供者
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 导出
    getExport() {
      this.$confirm(
        "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "导出提示",
        {
          confirmButtonText: "导出",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          let params = {};
          let par = {
            catalogName: this.catalogName || "",
            paperName: this.paperName || "",
            paperIsComplete: this.paperIsPass,
            paperUserType: this.paperUserType || "",
            compId: this.compId,
            userName: this.seaUserName || "",
            idcard: this.seaUserIdcard || "",
            mobile: this.seaUserMobile || "",
            projectCode: this.seaProjectCode || "",
            projectName: this.seaProjectName || "",
          };
          params = {
            ...params,
            ...par,
          };
          this.$post("/biz/paperUser/govListPageExport", params).then((res) => {
            if (res.status == "0") {
            let list = [];
            list.push(res.data);
            for (let item of list) {
              if (!this.downloadItems.includes(item.taskId)) {
                this.$store.dispatch("pushDownloadItems", item.taskId);
              } else {
                this.$message.warning(
                  "[" + item.fileName + "]已经申请下载,请耐心等待"
                );
              }
            }
          } else {
            this.$message.error(re.message);
          }
          });
        })
        .catch(() => {});
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 6) * 16;
      if (opDom) {
        // tHeight -= 34 + 40 + 40 + 0.675 * 16 + 1;
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    handledetail(row) {
      sessionStorage.setItem("rowJson", JSON.stringify(row));
      this.$router.push({
        path: "/web/operate/ExaminationDetails",
        query: {
          stu: "examinationJg",
          stu2: "Jg",
          userId: this.userId,
        },
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.operationControlExamination {
  > div {
    justify-content: space-between;
  }
  .el-form {
    display: flex;
    .el-form-item {
      margin-bottom: 0;
    }
    .btns {
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
  .searchboxs {
    justify-content: flex-start;
  }
}
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
